import useRegionalLocale from "hooks/useRegionalLocale";

function getLanguage(regionalLocale: string) {
  return new Intl.Locale(regionalLocale).language;
}

function useLanguage() {
  const { regionalLocale } = useRegionalLocale();
  const language = getLanguage(regionalLocale);
  return { language };
}

export default useLanguage;
