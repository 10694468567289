import { useTranslation } from "react-i18next";

export const getRegionalLocale = (appLocale: string) => {
  switch (appLocale) {
    case "de":
      return "de-de";
    case "fr":
      return "fr-fr";
    case "nb_private_customer":
    case "nb_sleep_research":
    case "nb_healthwelfare":
      return "nb-no";
    case "en":
    default:
      return "en-gb";
  }
};

function useRegionalLocale() {
  const { i18n } = useTranslation();
  const regionalLocale = getRegionalLocale(i18n.language);
  return { regionalLocale };
}

export default useRegionalLocale;
