import { Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Highcharts from "highcharts";
import HighchartsReact, {
  HighchartsReactRefObject,
} from "highcharts-react-official";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";

import { IHistoryStagePiePlotData } from "interfaces/sleephistory.interface";
import { createRef, memo, useCallback } from "react";
import { TFunction } from "i18next";

const SleepStagesPieChart = ({
  plotData,
}: {
  plotData: IHistoryStagePiePlotData;
}) => {
  const { t } = useTranslation();
  const chartRef = createRef<HighchartsReactRefObject>();
  const theme = useTheme();

  const onResize = useCallback(() => {
    chartRef.current?.chart.reflow();
  }, [chartRef]);

  const { ref } = useResizeDetector({
    handleWidth: true,
    onResize: onResize,
  });

  return (
    <div ref={ref}>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType="chart"
        options={getPlotOptions({ plotData, t, theme })}
      />
    </div>
  );
};

export default memo(SleepStagesPieChart);

const getPlotOptions = ({
  plotData,
  t,
  theme,
}: {
  plotData: IHistoryStagePiePlotData;
  t: TFunction;
  theme: Theme;
}) => {
  const colorType = "light";
  const series = plotData;
  return {
    chart: {
      backgroundColor: "transparent",
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    legend: {
      itemStyle: { color: theme.palette.chart.legend.selected[colorType] },
      itemHiddenStyle: {
        color: theme.palette.chart.legend.unselected[colorType],
        textDecoration: "none",
      },
      itemHoverStyle: { color: theme.palette.chart.legend.hover[colorType] },
    },
    title: { text: null },
    credits: { enabled: false },
    tooltip: { pointFormat: "<b>{point.percentage:.0f}%</b>" },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.percentage:.0f}%</b>",
          distance: -40,
          style: {
            color: "black",
            fontSize: 18,
            opacity: 1,
          },
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Sleep Stages",
        colorByPoint: true,
        data: [
          {
            name: t("Wake"),
            color: "rgba(139, 0, 0, 1)",
            y: series.timeAwake,
          },
          {
            name: t("REM"),
            color: "rgba(255, 140, 0, 1)",
            y: series.timeInREMSleep,
          },
          {
            name: t("Light"),
            color: "rgba(173, 216, 230, 1)",
            y: series.timeInLightSleep,
          },
          {
            name: t("Deep"),
            color: "rgba(0, 0, 139, 1)",
            y: series.timeInDeepSleep,
          },
        ],
      },
    ],
  };
};
