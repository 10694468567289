import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";

import useFormat, { FormatterType } from "hooks/useFormat";

const getCurrentDay = (format: FormatterType) => {
  const formattedDate = format(new Date(), "PPPPp");
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

function DateTimeText() {
  const { format } = useFormat();
  const intervalRef = useRef<number>(0);

  const [currentDateTime, setCurrentDateTime] = useState<string>(
    getCurrentDay(format),
  );

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setCurrentDateTime(getCurrentDay(format));
    intervalRef.current = window.setInterval(() => {
      setCurrentDateTime(getCurrentDay(format));
    }, 10 * 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [format]);

  return <Typography>{currentDateTime}</Typography>;
}
export default DateTimeText;
