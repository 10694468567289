import useFnsLocale from "./useFnsLocale";
import { format } from "date-fns";
import { useCallback } from "react";

export type FormatterType = (date: Date, formatStr: string) => string;

function useFormat() {
  const { fnsLocale } = useFnsLocale();

  const dateTimeFormat: FormatterType = useCallback(
    (date: Date, formatStr: string): string =>
      format(date, formatStr, {
        locale: fnsLocale,
      }),
    [fnsLocale],
  );

  return { format: dateTimeFormat };
}
export default useFormat;
