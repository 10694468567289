import { Theme } from "@mui/material";
import Highcharts from "highcharts/highstock";
import {
  IHistoryStagesPlotData,
  SleepStagesOptionsType,
} from "interfaces/sleephistory.interface";
import {
  ChartType,
  chartType as chartTypeOptions,
  HighchartsType,
} from "../sleepHistoryTypes";
import { FormatterType } from "hooks/useFormat";
import { TFunction } from "i18next";

export const getSleepHistoryStagesChartOptions = ({
  onChartMouseOver,
  options,
  t,
  theme,
  chartType,
  highchartsType,
  startDateMs,
  endDateMs,
  formatDuration,
  formatDate,
}: {
  onChartMouseOver: (e: Event) => void;
  options: SleepStagesOptionsType;
  t: TFunction;
  theme: Theme;
  chartType: ChartType;
  highchartsType: HighchartsType;
  startDateMs: number;
  endDateMs: number;
  formatDuration: (seconds: number) => string;
  formatDate: FormatterType;
}): Highcharts.Options => {
  const plotData = options.series;
  const threshold = options.threshold;
  // Deep copy the plotData to avoid mutating the original data
  const plotDataDeepCopy: IHistoryStagesPlotData = JSON.parse(
    JSON.stringify(plotData),
  );

  const colorType = "light";

  // Column chart options

  const columnChartOptions: {
    plotOptions: Highcharts.Options["plotOptions"];
  } = {
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
        pointPadding: 0.05,
        groupPadding: 0,
        borderWidth: 0,
        borderRadius: 1,
        crisp: false,
      },
    },
  };

  // Area chart options
  const areaChartOptions: {
    plotOptions: Highcharts.Options["plotOptions"];
  } = {
    plotOptions: {
      series: {
        stacking: "normal",
        threshold,
      },
      areaspline: {
        lineWidth: 0,
        trackByArea: true,
        gapSize: 36 * 60 * 60 * 1000, // Create a break in the areaspline if there is more than 36h between data.
        gapUnit: "value",
        marker: {
          lineWidth: 1,
          lineColor: "#666666",
          enabled: true,
          symbol: "circle",
        },
      },
    },
  };

  // relative session start
  if (chartType === chartTypeOptions.column_chart) {
    plotDataDeepCopy?.relativeSessionStart.forEach((session) => {
      if (session.y) {
        session.y += 86400;
      }
    });
  }

  const collection = plotDataDeepCopy?.relativeSessionStart;
  const minYAxisValue =
    collection && collection.length > 0
      ? collection.reduce(
          (min, item) =>
            (item.y ?? Number.MAX_VALUE) < (min.y ?? Number.MAX_VALUE)
              ? item
              : min,
          collection[0],
        ).y
      : null;
  const highchartOptions: Highcharts.Options = {
    chart: {
      type: highchartsType,
      marginRight: 48,
      marginLeft: 24,
      backgroundColor: "transparent",
      ignoreHiddenSeries: false,
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    title: {},
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      min: startDateMs,
      max: endDateMs,
      minTickInterval:
        plotDataDeepCopy?.relativeSessionStart?.length > 1
          ? 24 * 3600 * 1000
          : undefined, // One day in milliseconds
      labels: {
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          return formatDate(new Date(this.value), "MMM d");
        },
      },
      title: {
        text: t("date"),
      },
      ordinal: false,
    },
    yAxis: {
      min:
        chartType === chartTypeOptions.column_chart
          ? (minYAxisValue ?? 0 - 3600)
          : minYAxisValue,
      tickInterval: 3 * 3600,

      title: {
        text: null,
      },
      labels: {
        enabled: true,
        x: 35,
        formatter() {
          let dt = new Date(2000, 1, 1, 0, 0, 0, 0);
          // @ts-ignore
          dt = new Date(dt.getTime() + this.value * 1000);
          // 'en-US'
          return dt.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
            timeZone: "Europe/Oslo",
          });
        },
      },
    },
    scrollbar: {
      enabled: false,
    },
    legend: {
      enabled: true,
      itemHiddenStyle: {
        color: theme.palette.chart.legend.unselected[colorType],
        textDecoration: "none",
      },
    },
    plotOptions:
      chartTypeOptions.area_chart === chartType
        ? {
            ...areaChartOptions.plotOptions,
          }
        : {
            ...columnChartOptions.plotOptions,
          },
    navigator: {
      enabled: false,
      series: {
        data: plotDataDeepCopy.timeAsleep,
      },
      yAxis: {
        min: 0,
      },
    },
    rangeSelector: {
      enabled: false,
    },
    series: [
      {
        name: t("Sleep Onset"),
        type: highchartsType,
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        color: "rgba(255, 168, 168, 1)",
        data: plotDataDeepCopy.sleepOnset,
      },
      {
        name: t("Wake after sleep onset"),
        type: highchartsType,
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        color: "rgba(139, 0, 0, 1)",
        data: plotDataDeepCopy.timeWakeAfterSleepOnset,
      },
      {
        name: t("wake_after_sleep"),
        type: highchartsType,
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        color: "rgba(255, 255, 77, 1)",
        data: plotDataDeepCopy.wake_after_sleep,
        selected: false,
      },
      {
        name: t("REM Sleep"),
        type: highchartsType,
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        color: "rgba(255, 140, 0, 1)",
        showInNavigator: true,
        data: plotDataDeepCopy.timeInREMSleep,
      },
      {
        name: t("Light Sleep"),
        type: highchartsType,
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        color: "rgba(173, 216, 230, 1)",
        showInNavigator: true,
        data: plotDataDeepCopy.timeInLightSleep,
      },
      {
        name: t("Deep Sleep"),
        type: highchartsType,
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        color: "rgba(0, 0, 139, 1)",
        data: plotDataDeepCopy.timeInDeepSleep,
      },
      {
        name: t("Went to bed"),
        type: highchartsType,
        cursor: "pointer",
        point: {
          events: {
            mouseOver: onChartMouseOver,
          },
        },
        color: "transparent",
        data: plotDataDeepCopy.relativeSessionStart,
        showInLegend: false,
      },
    ],
    tooltip: {
      useHTML: true,
      shared: true,
      headerFormat: "",
      formatter() {
        // @ts-ignore
        const { points } = this;
        const pointsLength = points?.length ?? 0;
        const timestamp = new Date(points?.[0]?.key ?? "");
        const currentDate = formatDate(new Date(timestamp), "PPPp");
        const currentDateUppercasefirstChar =
          currentDate.charAt(0).toUpperCase() + currentDate.slice(1);

        let tooltipMarkup: string = pointsLength
          ? `<span style="font-size:10px;font-weight:bold;">${currentDateUppercasefirstChar}</span><br/>`
          : "";

        let index;
        for (index = 0; index < pointsLength; index += 1) {
          if (points?.[index].series.name === t("Went to bed")) {
            // tool tip markup for Went to bed
          } else {
            tooltipMarkup += `<span style="color:${points?.[index].series.color}">\u25CF</span> ${points?.[index].series.name}: <b>`;
            tooltipMarkup += formatDuration(points?.[index].y ?? 0);
            tooltipMarkup += "</b><br/>";
          }
        }
        return tooltipMarkup;
      },
    },
  };

  // highchart options
  return highchartOptions;
};
