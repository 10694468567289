import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import { type IZoneUserRole, ZoneUserRole } from "constants/userContstants";

// ListItemIconStyled
export const ListItemIconStyled = styled(ListItemIcon)(({
  active,
}: {
  active?: number;
}) => {
  const theme = useTheme();
  return {
    marginRight: 10,
    minWidth: "20px",
    "& > svg": {
      color: active ? theme.palette.main[50] : theme.palette.red[50],
    },
  };
});

export const ListItemTextStyled = styled(ListItemText)(({
  active,
}: {
  active?: number;
}) => {
  const theme = useTheme();
  return {
    color: active ? theme.palette.neutral[80] : theme.palette.neutral[30],
  };
});

export const TitleStyled = styled("p")(() => {
  const theme = useTheme();
  return {
    margin: 0,
    padding: 0,
    color: theme.palette.neutral[80],
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.1px",
    fontSize: 14,
    marginBottom: 8,
  };
});
export const SvgIconStyled = styled(SvgIcon)(() => {
  const theme = useTheme();
  return {
    color: theme.palette.red[50],
    marginRight: "10px",
  };
});
export const TitleNotAllowedStyled = styled("div")(() => {
  const theme = useTheme();
  return {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    marginBottom: 34,
    "& p": {
      margin: 0,
      padding: 0,
      color: theme.palette.neutral[80],
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.1px",
      fontSize: 14,
    },
  };
});

function RoleInformation({
  roles,
  title,
  contentRef,
  notAllowedTitle,
}: {
  roles: IZoneUserRole[];
  title?: string;
  contentRef?: any;
  notAllowedTitle?: string;
}) {
  const { t } = useTranslation();

  const isActive = (permissions: IZoneUserRole[]): number => {
    const filteredArray =
      permissions.filter((item) => roles.includes(item)) || [];
    return filteredArray.length ? 1 : 0;
  };
  const userRoles: {
    name: string;
    value: string;
    permissions: IZoneUserRole[];
  }[] = [
    {
      name: t("manage_zones"),
      value: "manage_zones",
      permissions: [ZoneUserRole.OWNER],
    },
    {
      name: t("manage_users_and_assign_roles_to_zones"),
      value: "manage_users_and_assign_roles_to_zones",
      permissions: [ZoneUserRole.OWNER, ZoneUserRole.ADMIN],
    },
    {
      name: t("manage_somnofy_devices"),
      value: "manage_somnofy_devices",
      permissions: [ZoneUserRole.OWNER, ZoneUserRole.ADMIN],
    },
    {
      name: t("manage_subjects"),
      value: "manage_subjects",
      permissions: [
        ZoneUserRole.OWNER,
        ZoneUserRole.ADMIN,
        ZoneUserRole.MANAGER,
      ],
    },

    {
      name: t("assign_somnofy_device_to_subject"),
      value: "assign_somnofy_device_to_subject",
      permissions: [
        ZoneUserRole.OWNER,
        ZoneUserRole.ADMIN,
        ZoneUserRole.MANAGER,
      ],
    },
    {
      name: t("change_somnofy_device_settings"),
      value: "change_somnofy_device_settings",
      permissions: [
        ZoneUserRole.OWNER,
        ZoneUserRole.ADMIN,
        ZoneUserRole.MANAGER,
        ZoneUserRole.MANAGER_LIMITED,
      ],
    },
    {
      name: t("manage_notifications"),
      value: "manage_notifications",
      permissions: [
        ZoneUserRole.OWNER,
        ZoneUserRole.ADMIN,
        ZoneUserRole.MANAGER,
      ],
    },
    {
      name: t("view_live_dashboard"),
      value: "view_live_dashboard",
      permissions: [
        ZoneUserRole.DATA_READER,
        ZoneUserRole.DATA_READER_LIMITED,
        ZoneUserRole.SERVICE_MONITOR,
      ],
    },
    {
      name: t("view_night_report"),
      value: "view_night_report",
      permissions: [ZoneUserRole.DATA_READER, ZoneUserRole.DATA_READER_LIMITED],
    },
    {
      name: t("view_sleep_history"),
      value: "view_sleep_history",
      permissions: [ZoneUserRole.DATA_READER],
    },
    {
      name: t("stay_logged_in"),
      value: "stay_logged_in",
      permissions: [ZoneUserRole.SERVICE_MONITOR],
    },
  ];
  return (
    <div ref={contentRef || null}>
      {notAllowedTitle && (
        <TitleNotAllowedStyled>
          <SvgIconStyled>
            <LockIcon />
          </SvgIconStyled>
          <p>{notAllowedTitle}</p>
        </TitleNotAllowedStyled>
      )}
      <TitleStyled>{title || ""}</TitleStyled>
      <List disablePadding>
        {userRoles.map((item) => {
          const active = isActive(item.permissions);
          return (
            <ListItem disablePadding key={item.value}>
              <ListItemIconStyled active={active}>
                {active ? <CheckIcon /> : <CloseIcon />}
              </ListItemIconStyled>
              <ListItemTextStyled primary={item.name} active={active} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}
export default RoleInformation;
