import { de, enGB, fr, nb } from "date-fns/locale";
import useRegionalLocale from "hooks/useRegionalLocale";
import { Locale } from "date-fns";

export const getFnsLocale = (locale: string): Locale => {
  switch (locale) {
    case "nb-no":
      return nb;
    case "de-de":
      return de;
    case "fr-fr":
      return fr;
    case "en-gb":
    default:
      return enGB;
  }
};

function useFnsLocale() {
  const { regionalLocale } = useRegionalLocale();
  const fnsLocale = getFnsLocale(regionalLocale);
  return { fnsLocale };
}

export default useFnsLocale;
