import { encode as base64encode } from "base64-arraybuffer";
import Cookies from "js-cookie";

import * as LOCAL_STORAGE from "utils/localStorage";
import { LOG_OFF_API, PROD_AUTH_BASE_URL } from "constants/auth.constants";
import { ZoneUserRole } from "constants/userContstants";

export const generateState = () => {
  const validChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let array = new Uint8Array(56) as any;
  window.crypto.getRandomValues(array);
  array = array.map((x: number) =>
    validChars.codePointAt(x % validChars.length),
  );
  const randomState = String.fromCharCode.apply(null, array);
  return randomState;
};

export const shouldRefreshAccessToken = (roles: string[]) => {
  return roles.length === 1 && roles.includes(ZoneUserRole.SERVICE_MONITOR);
};

//
export const enhanceAuthorizeUrl = ({
  clientId,
  state,
  code_challenge,
  authorizeUrl,
  redirectUri,
  loginHint,
}: {
  clientId: string;
  state: string;
  code_challenge: string;
  authorizeUrl: string;
  redirectUri: string;
  loginHint: string;
}) => {
  const redirect = `${document.location.origin}${redirectUri}`;
  const responseType = "code";
  const code_challenge_method = "S256";
  const scope = "offline_access";
  return `${authorizeUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirect}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=${code_challenge_method}&scope=${scope}&login_hint=${encodeURIComponent(
    loginHint,
  )}`;
};

//
export async function generateCodeChallenge(codeVerifier: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest("SHA-256", data);
  const base64Digest = base64encode(digest);
  // you can extract this replacing code to a function
  return base64Digest.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

//
export const clearCookies = () => {
  Cookies.remove("access_token");
  Cookies.remove("refresh_token");
  Cookies.remove("userId");
  Cookies.remove("logged_user_email");

  LOCAL_STORAGE.removeZone();
};

//
export const getLogoutUrl = () => {
  const client_id = LOCAL_STORAGE.getClientId();
  const url = `${getAuthBaseUrl() + LOG_OFF_API}?client_id=${client_id}`;

  return url;
};

export const getAuthBaseUrl = () => {
  if (import.meta.env.VITE_AUTH_BACKENDS) {
    const customAuthBackend = LOCAL_STORAGE.getCustomAuthBackend();
    return customAuthBackend ? customAuthBackend : PROD_AUTH_BASE_URL;
  }

  return PROD_AUTH_BASE_URL;
};
