import { AppRoute } from "interfaces/router.interface";
import { ZoneUserRole } from "constants/userContstants";
import AdministrationScreen from "@modules/administration/AdministrationScreen";
import MonitorScreen from "@modules/monitor/DashboardScreen";
import UserProfileScreen from "@modules/administration/userProfile/UserProfileScreen";

const adminRoutes: AppRoute[] = [
  {
    path: "monitor/*",
    element: <MonitorScreen />,
    link: {
      href: "/monitor",
      name: "dashboard",
    },
    allowedRoles: [
      ZoneUserRole.DATA_READER,
      ZoneUserRole.DATA_READER_LIMITED,
      ZoneUserRole.SERVICE_MONITOR,
    ],
  },
  {
    path: "administration/*",
    element: <AdministrationScreen />,
    link: {
      href: "/administration",
      name: "Administration",
    },
    allowedRoles: [
      ZoneUserRole.ADMIN,
      ZoneUserRole.MANAGER,
      ZoneUserRole.MANAGER_LIMITED,
      ZoneUserRole.OWNER,
    ],
  },
  {
    path: "profile",
    element: <UserProfileScreen />,
    allowedRoles: [
      ZoneUserRole.ADMIN,
      ZoneUserRole.MANAGER,
      ZoneUserRole.MANAGER_LIMITED,
      ZoneUserRole.OWNER,
      ZoneUserRole.DATA_READER,
      ZoneUserRole.DATA_READER_LIMITED,
    ],
  },
];

export default adminRoutes;
