import { IRole } from "interfaces/user.interface";
import { TFunction } from "i18next";

export const ZoneUserRole = {
  OWNER: "OWNER",
  ADMIN: "ADMIN",
  MANAGER: "MANAGER",
  MANAGER_LIMITED: "MANAGER_LIMITED",
  SERVICE_MONITOR: "SERVICE_MONITOR",
  DATA_READER: "DATA_READER",
  DATA_READER_LIMITED: "DATA_READER_LIMITED",
} as const;

export type IZoneUserRole = (typeof ZoneUserRole)[keyof typeof ZoneUserRole];

export const operativeRoles = {
  [ZoneUserRole.DATA_READER_LIMITED]: 1,
  [ZoneUserRole.DATA_READER]: 2,
} as const;

export const administrativeRoles = {
  [ZoneUserRole.MANAGER_LIMITED]: 3,
  [ZoneUserRole.MANAGER]: 4,
  [ZoneUserRole.ADMIN]: 5,
  [ZoneUserRole.OWNER]: 6,
} as const;

// operative roles
export const getOperativeRoles = (t: TFunction): IRole[] => {
  return [
    {
      name: t("role_date_reader_limited"),
      value: ZoneUserRole.DATA_READER_LIMITED,
      activeChipTheme: "teal",
      tooltip: t("role_date_reader_limited_info"),
      order: 1,
      type: "operative",
    },
    {
      name: t("role_date_reader"),
      value: ZoneUserRole.DATA_READER,
      activeChipTheme: "blue",
      tooltip: t("role_date_reader_info"),
      order: 2,
      type: "operative",
    },
  ];
};

// Administrative User Roles
export const getAdministrativeUserRoles = (t: TFunction): IRole[] => {
  const ZoneUserRoles: IRole[] = [
    {
      name: t("role_manager_limited"),
      value: ZoneUserRole.MANAGER_LIMITED,
      activeChipTheme: "purple",
      tooltip: t("role_manager_limited_info"),
      order: 3,
      type: "administrative",
    },
    {
      name: t("role_manager"),
      value: ZoneUserRole.MANAGER,
      activeChipTheme: "yellow",
      tooltip: t("role_manager_info"),
      order: 4,
      type: "administrative",
    },
    {
      name: t("role_admin"),
      value: ZoneUserRole.ADMIN,
      activeChipTheme: "orange",
      tooltip: t("role_admin_info"),
      order: 5,
      type: "administrative",
    },
    {
      name: t("role_owner"),
      value: ZoneUserRole.OWNER,
      activeChipTheme: "pink",
      tooltip: t("role_owner_info"),
      order: 6,
      type: "administrative",
    },
  ];
  return ZoneUserRoles;
};

export const getServiceMonitorRole = (t: TFunction): IRole => {
  return {
    name: t("role_service_account"),
    value: ZoneUserRole.SERVICE_MONITOR,
    activeChipTheme: "green",
    tooltip: t("role_service_account_info"),
    order: 7,
    type: "service_monitor",
  };
};

export const getZoneUserRolesAsEntity = (t: TFunction) => {
  const entity: {
    [key: string]: IRole;
  } = {};
  getOperativeRoles(t)
    .concat(getAdministrativeUserRoles(t))
    .concat([getServiceMonitorRole(t)])
    .forEach((role) => {
      entity[role.value] = role;
    });
  return entity;
};
