import "./i18n/config";
import * as Sentry from "@sentry/react";
import { Route, Routes } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useState } from "react";
import axios from "axios";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { IWIPMessage } from "interfaces/wipMessage.interface";
import { WIPMessageUrl } from "constants/constants";
import { appTheme } from "theme/default/somnofy";
import { jwtInterceptor } from "interceptors/axios.interceptor";

import { GlobalContext } from "./global/GlobalContext";
import AdminLayoutNew from "./layouts/AdminLayout";
import AuthLayoutNew from "./layouts/AuthNew";
import HomeLayout from "./layouts/Home";
import RequireLogin from "./services/utils/RequireLogin";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-mono";
import useFnsLocale from "hooks/useFnsLocale";

// Sentry integration
if (import.meta.env.VITE_SENTRY_ENV) {
  Sentry.init({
    dsn: "https://1e6cf856b7504037a3ace15b2d2e811d@o197106.ingest.sentry.io/4504558544945152",
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_SENTRY_ENV,
    release: import.meta.env.VITE_GIT_SHA,
  });
}

// APP
function App() {
  jwtInterceptor();
  const { fnsLocale } = useFnsLocale();

  const [appWarningMessage, setAppWarningMessage] =
    useState<IWIPMessage | null>(null);
  const filePath =
    import.meta.env.MODE === "production" ? "prod.json" : "dev.json";
  useEffect(() => {
    axios({
      method: "get",
      url: `${WIPMessageUrl}/${filePath}`,
      timeout: 30000,
      transformRequest: (data, headers) => {
        delete headers.Authorization;
        return data;
      },
    })
      .then((response) => {
        if (response?.data) {
          setAppWarningMessage(response?.data);
        }
      })
      .catch((error) => {
        console.log("Eror while loading warning message", error);
      });
  }, [filePath]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <CssBaseline>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={fnsLocale}
          >
            <ToastContainer
              hideProgressBar
              position="bottom-right"
              autoClose={7000}
              toastClassName="toast-custom-styles"
              style={{
                width: "auto",
                right: 64,
                bottom: 24,
              }}
            />
            <GlobalContext.Provider
              value={{
                appWarningMessage,
              }}
            >
              <Routes>
                <Route path="auth/*" element={<AuthLayoutNew />} />
                <Route path="home/*" element={<HomeLayout />} />
                <Route
                  path="*"
                  element={
                    <RequireLogin>
                      <AdminLayoutNew />
                    </RequireLogin>
                  }
                />
              </Routes>
            </GlobalContext.Provider>
          </LocalizationProvider>
        </CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
