import { useTheme, styled } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppGlobalContextType, GlobalContext } from "global/GlobalContext";
import { IWIPMessage, isWIPMessageType } from "interfaces/wipMessage.interface";
import useLanguage from "hooks/useLanguage";

// is utc date in range
function isUtcDateInRange(fromDate: Date, toDate: Date) {
  const currentDate = new Date();
  const now = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds(),
  );

  const from = Date.UTC(
    fromDate.getFullYear(),
    fromDate.getMonth(),
    fromDate.getDate(),
    fromDate.getHours(),
    fromDate.getMinutes(),
    fromDate.getSeconds(),
  );
  const to = Date.UTC(
    toDate.getFullYear(),
    toDate.getMonth(),
    toDate.getDate(),
    toDate.getHours(),
    toDate.getMinutes(),
    toDate.getSeconds(),
  );
  return now >= from && now <= to;
}

const WarningMessageStyled = styled("div")(() => {
  const theme = useTheme();
  return {
    top: "100px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    position: "absolute",
    marging: "10px",
    "& >div": {
      color: theme.palette.red[50],
      border: `1px solid ${theme.palette.neutral[5]}`,
      padding: 10,
      textAlign: "center",
      "& h2": {
        margin: "10px 0",
        padding: 0,
        fontWeight: "normal",
        fontSize: 18,
      },
      "& p": {
        margin: 0,
        padding: 0,
        fontWeight: "normal",
      },
    },
  };
});

function getObjectValue(obj: any, path: string[]) {
  let value = obj;
  for (let i = 0; i < path.length; i += 1) {
    if (value[path[i]]) {
      value = value[path[i]];
    }
  }
  return value;
}

// warning message styled
function WIPMessage() {
  const location = useLocation();
  const { language } = useLanguage();
  const [wipMessages, setWipMessages] = useState<IWIPMessage[]>();
  const messages = useContext<AppGlobalContextType | null>(GlobalContext);
  useEffect(() => {
    if (messages?.appWarningMessage) {
      const paths = location.pathname.split("/").filter((val) => val) || [];
      let message;
      if (paths.length) {
        message = getObjectValue(messages?.appWarningMessage, paths);
        if (message && isWIPMessageType(message)) {
          setWipMessages(message as IWIPMessage[]);
        }
      }
    }
  }, [messages, location.pathname]);

  if (!wipMessages) {
    return <div />;
  }
  // is date in range
  return (
    <>
      {wipMessages.map((wipMessage: IWIPMessage, index) => {
        return isUtcDateInRange(
          new Date(wipMessage.enable_from),
          new Date(wipMessage.enable_to),
        ) && wipMessage.enabled ? (
          <WarningMessageStyled key={index}>
            {language === "en" ? (
              <MessageComponent
                title={
                  wipMessage.message.en?.title || wipMessage.message.no.title
                }
                body={wipMessage.message.en?.body || wipMessage.message.no.body}
              />
            ) : (
              <MessageComponent
                title={wipMessage.message.no.title}
                body={wipMessage.message.no.body}
              />
            )}
          </WarningMessageStyled>
        ) : null;
      })}
    </>
  );
}
export default WIPMessage;

// message component
const MessageComponent = ({ title, body }: { title: string; body: string }) => {
  return (
    <div>
      <h2>{title || ""}</h2>
      <p dangerouslySetInnerHTML={{ __html: `${body}` }} />
    </div>
  );
};
