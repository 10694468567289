import axios from "axios";
import Cookies from "js-cookie";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useLocation } from "react-router-dom";

import { isAllowed } from "authorization/authorization.util";
import { PROD_API_BASE_URL } from "constants/constants";
import { ZoneUserRole } from "constants/userContstants";
import { IAccessTokenPayload } from "interfaces/auth.interface";
import { IUser } from "interfaces/user.interface";
import { jwtDecode } from "jwt-decode";
import { getAuthUser } from "store/actions/auth/authActions";
import { clearCookies } from "utils/auth/auth.util";
import { useNavigate } from "./useNavigate";
import * as LOCAL_STORAGE from "utils/localStorage";
import { removeLanguagePreference, setLanguage } from "utils/localStorage";
import { useAppDispatch, useAppSelector } from "store";
import { refreshAuthToken, type RefreshTokenData } from "api/authentication";

const refreshAccessTokenQuery = (data: RefreshTokenData) => {
  refreshAuthToken(data)
    .then((response) => {
      if (response.data) {
        if (response.data.access_token && response.data.refresh_token) {
          const accessTokenLifetime = response.data.expires_in || 0;
          const accessTokenExpiryDate = new Date();
          accessTokenExpiryDate.setTime(
            accessTokenExpiryDate.getTime() + accessTokenLifetime * 1000,
          );
          Cookies.set("access_token", response.data.access_token, {
            expires: accessTokenExpiryDate,
          });
          Cookies.set("userId", response.data.userId, {
            expires: accessTokenExpiryDate,
          });
          Cookies.set("refresh_token", response.data.refresh_token);
        }
      }
    })
    .catch(() => {
      clearCookies();
    });
};

const refreshAccessToken = (navigate: NavigateFunction) => {
  const client_id = LOCAL_STORAGE.getClientId();
  const refresh_token = Cookies.get("refresh_token");

  if (client_id && refresh_token) {
    refreshAccessTokenQuery({ client_id, refresh_token });
  } else {
    navigate("/auth/login");
  }
};

export const useAuthenticatedUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const location = useLocation();
  const [allowTokenRefresh, setAllowTokenRefresh] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState<IUser | false>();
  const intervalId = useRef<undefined | number>(undefined);
  const authState = useAppSelector((state) => state.authReducerV1);

  useEffect(() => {
    if (import.meta.env.VITE_API_END_POINTS) {
      const baseURL = LOCAL_STORAGE.getCustomAPIEndPoint() || PROD_API_BASE_URL;
      axios.defaults.baseURL = baseURL;
    } else {
      axios.defaults.baseURL = PROD_API_BASE_URL;
    }
    if (Cookies.get("access_token")) {
      dispatch(getAuthUser());
    } else {
      setAuthUser(false);
    }
  }, [dispatch]);

  const onFocus = useCallback(() => {
    const accessToken = Cookies.get("access_token");
    if (!accessToken) {
      clearCookies();
      setAuthUser(false);
    }
    if (document.visibilityState === "visible") {
      if (accessToken) {
        dispatch(getAuthUser());
      }
    }
  }, [dispatch]);
  // refresh on auth user on
  useEffect(() => {
    document.addEventListener("visibilitychange", onFocus);
    return () => {
      document.removeEventListener("visibilitychange", onFocus);
    };
  }, [onFocus]);

  useEffect(() => {
    if (authState.user) {
      setAuthUser(authState.user);
      Cookies.set("logged_user_email", authState.user.email);

      const language = authState.user.preferences?.language;
      if (language && language !== i18n.language) {
        setLanguage(language);
        i18n.changeLanguage(language);
      }

      const isPathToDashboard = location.pathname.includes("monitor/overview");
      const isAllowedToViewDashboard = isAllowed(authState.user, [
        ZoneUserRole.DATA_READER,
        ZoneUserRole.DATA_READER_LIMITED,
        ZoneUserRole.SERVICE_MONITOR,
      ]);
      if (isPathToDashboard && !isAllowedToViewDashboard) {
        navigate("/administration/zones/devices");
      }

      const isAllowedRefreshToken = isAllowed(authState.user, [
        ZoneUserRole.SERVICE_MONITOR,
      ]);
      setAllowTokenRefresh(isAllowedRefreshToken);
    } else if (Object.keys(authState).length !== 0) {
      setAuthUser(false);
      setAllowTokenRefresh(false);
      clearCookies();
      removeLanguagePreference();
    }
  }, [authState.user, i18n, location.pathname, authState, navigate]);

  // Refresh access token periodically
  useEffect(() => {
    clearInterval(intervalId.current);
    const accessToken = Cookies.get("access_token");
    if (!accessToken) return;
    if (!allowTokenRefresh) return;

    const { iat, exp } = jwtDecode<IAccessTokenPayload>(accessToken);
    if (iat && exp) {
      const delaySeconds = (exp - iat) / 2;

      intervalId.current = window.setInterval(
        () => refreshAccessToken(navigate),
        delaySeconds * 1000,
      );
    }

    return () => {
      clearInterval(intervalId.current);
    };
  }, [allowTokenRefresh, navigate]);

  return { authUser };
};
