import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { ClickAwayListener, TableCell, Tooltip, Box } from "@mui/material";
import {
  AlertMetric,
  NightReport,
  NightReportMetric,
  NightReportStatus,
} from "interfaces/nightReport.interface";
import { MouseEvent, RefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { ShortSpace } from "styled/CommonStyled";
import { useFormatDuration } from "hooks/useFormatDuration";
import {
  NightReportColumnNoAlert,
  NightReportColumnNoData,
} from "../NightReportColumn";
import {
  DivWrapper,
  ErrorOutlineWrapperStyled,
  TableRowStyled,
  ValueWrapperNoWidth,
} from "./NightReportStyledComponents";
import useLanguage from "hooks/useLanguage";

const RenderAlert = ({
  metricType,
  render,
}: {
  metricType: AlertMetric;
  render: boolean;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const tooltipText = `${t(metricType)}: ${
    metricType === "respiration_rate" ? "> 15%" : "> 30%"
  } ${t("difference_to_average")}`;

  if (!render) return <></>;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        title={tooltipText}
        placement="top"
        onClose={() => setOpen(false)}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        open={open}
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
          setOpen((prev) => !prev);
        }}
      >
        <ErrorOutlineWrapperStyled>
          <ErrorOutlineIcon />
        </ErrorOutlineWrapperStyled>
      </Tooltip>
    </ClickAwayListener>
  );
};

interface NightReportTableRowProps {
  row: NightReport;
  onClickRow: (row: NightReport) => void;
  lastChildRef: RefObject<HTMLTableRowElement | null> | null;
}
const NightReportTableRow = ({
  row,
  onClickRow,
  lastChildRef,
}: NightReportTableRowProps) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const formatDuration = useFormatDuration();

  if (
    row.state === NightReportStatus.NO_PRESENCE ||
    row.state === NightReportStatus.NO_SLEEP
  ) {
    return (
      <TableRowStyled
        onClick={() => onClickRow(row)}
        tabIndex={-1}
        key={row.device?.id}
        ref={lastChildRef}
      >
        <TableCell>{row.device?.name}</TableCell>
        <TableCell>{row.subject?.identifier}</TableCell>

        <TableCell align="left" colSpan={7}>
          <NightReportColumnNoData
            message={
              row.state === NightReportStatus.NO_PRESENCE
                ? t("subject_not_present_or_data_not_available")
                : t("No sleep data available")
            }
          />
        </TableCell>
      </TableRowStyled>
    );
  }

  const isBaselineNightLessthanThreshold = (row: NightReport) =>
    row.baseline_night_count !== null && row.baseline_night_count <= 2;

  const getRoundedValue = (row?: NightReportMetric | null) =>
    row?.value !== undefined ? Math.round(row.value) : t("N/A");

  const getDeviationPCT = (
    value: number,
    round: number,
    language: string,
    hideOperator: boolean = false,
  ) => {
    if (!value) return "";
    if (round > 0) {
      return `${value > 0 && !hideOperator ? "+" : ""}${value.toFixed(round)}`;
    }
    const val = `${value > 0 && !hideOperator ? "+" : ""}${Math.round(value)}`;
    if (language !== "en") val.replace(".", ",");
    return val;
  };

  const rowIsBelowThreshold: boolean = isBaselineNightLessthanThreshold(row);

  const formatNightReportTableRowContent = ({
    belowThresholdValue,
    reportMetric,
    deltaValue,
    hideOperator,
    hidePercent,
    renderType,
  }: {
    belowThresholdValue: number | string;
    reportMetric: NightReportMetric | null;
    deltaValue: number | undefined | null;
    hideOperator?: boolean;
    hidePercent?: boolean;
    renderType?: AlertMetric;
  }) =>
    rowIsBelowThreshold ? (
      <NightReportColumnNoAlert value={belowThresholdValue} />
    ) : (
      <DivWrapper>
        {renderType && (
          <span className="iconAlertWrapper">
            {
              <RenderAlert
                metricType={renderType}
                render={!!reportMetric?.deviation_alert}
              />
            }
          </span>
        )}
        <ValueWrapperNoWidth>
          {getRoundedValue(reportMetric)}{" "}
          {!!deltaValue && (
            <>
              ({getDeviationPCT(deltaValue, 0, language, hideOperator)}
              {!hidePercent && (
                <>
                  <ShortSpace>&nbsp;</ShortSpace>%
                </>
              )}
              )
            </>
          )}
        </ValueWrapperNoWidth>
      </DivWrapper>
    );

  const respirationRateContent = formatNightReportTableRowContent({
    belowThresholdValue: row.respiration_rate?.value
      ? Math.round(row.respiration_rate?.value)
      : "",
    reportMetric: row.respiration_rate,
    deltaValue: row.respiration_rate?.deviation_pct,
    renderType: "respiration_rate",
  });

  const movementContent = formatNightReportTableRowContent({
    belowThresholdValue: row.movement?.value
      ? `${Math.round(row.movement?.value)}`
      : "",
    reportMetric: row.movement,
    deltaValue: row.movement?.deviation_pct,
    renderType: "movement",
  });

  const sleepTimeContent = rowIsBelowThreshold ? (
    <NightReportColumnNoAlert
      value={
        row?.time_asleep?.value
          ? formatDuration(row?.time_asleep?.value)
          : t("N/A")
      }
    />
  ) : (
    <DivWrapper>
      <span className="iconAlertWrapper">
        <RenderAlert
          metricType="time_asleep"
          render={!!row?.time_asleep?.deviation_alert}
        />
      </span>
      <ValueWrapperNoWidth>
        {row?.time_asleep?.value
          ? formatDuration(row?.time_asleep?.value)
          : t("N/A")}
        {row.time_asleep?.deviation_pct && (
          <>
            ({getDeviationPCT(+row.time_asleep?.deviation_pct, 0, language)}
            <ShortSpace>&nbsp;</ShortSpace>%)
          </>
        )}
      </ValueWrapperNoWidth>
    </DivWrapper>
  );

  const sleepScore = formatNightReportTableRowContent({
    belowThresholdValue:
      row.sleep_score &&
      row.sleep_score.value !== null &&
      row.sleep_score.value >= 0
        ? Math.round(row.sleep_score?.value)
        : "",
    reportMetric: row.sleep_score,
    deltaValue: row.sleep_score?.baseline,
    hideOperator: true,
    hidePercent: true,
  });

  const outOfBedContent = formatNightReportTableRowContent({
    belowThresholdValue: getRoundedValue(row.out_of_bed),
    reportMetric: row.out_of_bed,
    deltaValue: row.out_of_bed?.baseline,
    hideOperator: true,
    hidePercent: true,
  });

  return (
    <TableRowStyled
      onClick={() => onClickRow(row)}
      tabIndex={-1}
      key={row.device?.id}
      ref={lastChildRef}
    >
      <TableCell>{row.device?.name}</TableCell>
      <TableCell>{row.subject?.identifier}</TableCell>
      <TableCell align="center">{respirationRateContent}</TableCell>
      <TableCell align="center">{movementContent}</TableCell>
      <TableCell align="center">{sleepTimeContent}</TableCell>
      <TableCell align="center">{sleepScore}</TableCell>
      <TableCell align="center">{outOfBedContent}</TableCell>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            columnGap: "5px",
            justifyContent: "center",
          }}
        >
          <div>{row.selected_session_is_in_progress ? t("Yes") : t("No")}</div>
          <Box sx={{ visibility: "hidden" }} width="24px" />
        </Box>
      </TableCell>
      <TableCell>
        {row.baseline_night_count ? `${row.baseline_night_count}` : t("N/A")}
      </TableCell>
    </TableRowStyled>
  );
};

export default NightReportTableRow;
